import React from 'react';

import Logo from '../../../static/svg/logo_white.svg';

import './Footer.scss';

const componentClass = 'footer';
const contentClass = `${componentClass}__content`;
const logoClass = `${componentClass}__logo`;

const Footer = () => {
  /*
  const phoneClick = () => window.gtag('event', 'click', { send_to: 'phone' });
  const emailClick = () => window.gtag('event', 'click', { send_to: 'email' });
  */

  return (
    <div id="kontakt" className={componentClass}>
      <div className={contentClass}>
        <h2>Kontakt</h2>

        <div>
          <strong>ABUNDATIA s.r.o.</strong>
          <br />
          IČ: 04529197
        </div>
        <div>
          <strong>Kontaktní osoba</strong>
          <br />
          Ludvík Nežádal
          <br />
          Tel.: <a href="tel:+420 776 045 824">+420 776 045 824</a>
          <br />
          Email:{' '}
          <a href="mailto:abundatia.stavby@seznam.cz">
            abundatia.stavby@seznam.cz
          </a>
        </div>

        <Logo className={logoClass} />
      </div>
    </div>
  );
};

export default Footer;
