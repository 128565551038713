import React from 'react';

import './Intro.scss';

const componentClass = 'intro';
const contentClass = `${componentClass}__content`;
const bgClass = `${componentClass}__bg`;

const Intro = () => {
  return (
    <div className={componentClass}>
      <div className={contentClass}>
        <p>Rodinná firma provádějící</p>
        <h1>zemní a stavební práce</h1>
      </div>

      <div className={bgClass}>
        <img
          src="/intro.jpg"
          srcSet="intro_768.jpg 768w, intro_1280.jpg 1280w, intro_1920.jpg 1920w, intro.jpg 2560w"
          alt=""
        />
      </div>
    </div>
  );
};

export default Intro;
