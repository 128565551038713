import React from 'react';
import classNames from 'classnames';

import './Fleet.scss';

const componentClass = 'fleet';
const contentClass = `${componentClass}__content`;
const listClass = `${componentClass}__list`;

const Fleet = () => {
  const items = [
    {
      name: 'Smykový nakladač Cat&nbsp;242D3',
      price: 'Cena od 700 Kč / hod',
      photo: 'fleet1.jpg'
    },
    {
      name: 'Pásové rypadlo Takeuchi&nbsp;TB&nbsp;235-2',
      price: 'Cena od 700 Kč / hod',
      photo: 'fleet2x.jpg'
    },
    {
      name: 'Pásové rypadlo Volvo&nbsp;ECR&nbsp;88',
      price: 'Cena od 1200 Kč / hod',
      photo: 'fleet3.jpg'
    },
    {
      name: 'Nákladní automobil SCANIA',
      price: 'Cena dohodou',
      photo: 'fleet4.jpg'
    }
  ];

  const addons = [
    'Domíchávací lžíce na beton',
    'Paletyzační vidle',
    'Bourací kladiva',
    'Vibrační pěch',
    'Naklápěcí svahové lopaty'
  ];

  return (
    <div id="technika" className={componentClass}>
      <div className={contentClass}>
        <h2>Naše technika</h2>

        <div className={listClass}>
          {items.map(({ name, price, photo }, idx) => {
            return (
              <div key={idx}>
                <img src={`/${photo}`} />
                <h4 dangerouslySetInnerHTML={{ __html: name }} />
                <span>{price}</span>
              </div>
            );
          })}
        </div>

        <h3>Příslušenství ke strojům</h3>

        <ul>
          {addons.map((i, idx) => (
            <li key={idx}>{i}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Fleet;
