import React from 'react';

import './References.scss';

const componentClass = 'references';
const contentClass = `${componentClass}__content`;
const listClass = `${componentClass}__list`;

const References = () => {
  const items = [
    {
      photo: 'reference1.jpg'
    },
    {
      photo: 'reference2.jpg'
    },
    {
      photo: 'reference4.jpg'
    },
    {
      photo: 'reference5.jpg'
    },
    {
      photo: 'reference3.jpg'
    },
    {
      photo: 'reference6.jpg'
    },
    {
      photo: 'reference7.jpg'
    },
    {
      photo: 'reference8.jpg'
    },
    {
      photo: 'reference9.jpg'
    },
    {
      photo: 'reference10.jpg'
    },
    {
      photo: 'reference11.jpg'
    },
    {
      photo: 'reference12.jpg'
    },
    {
      photo: 'reference13.jpg'
    },
    {
      photo: 'reference14.jpg'
    },
    {
      photo: 'reference15.jpg'
    }
  ];

  return (
    <div id="reference" className={componentClass}>
      <div className={contentClass}>
        <h2>Reference</h2>

        <div className={listClass}>
          {items.map(({ photo }, idx) => {
            return (
              <div key={idx}>
                <img alt="" src={`/${photo}`} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default References;
