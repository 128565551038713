import React from 'react';

import './Services.scss';

const componentClass = 'services';
const contentClass = `${componentClass}__content`;
const servicesClass = `${componentClass}__services`;
const mapClass = `${componentClass}__map`;
const mapContentClass = `${componentClass}__mapContent`;
const bgClass = `${componentClass}__bg`;

const Services = () => {
  const items = [
    'Základové desky na klíč',
    'Terénní úpravy',
    'Opěrné zdi',
    'Realizace parkovacích stání',
    'Napojení inženýrských sítí',
    'Zhotovení tlakové kanalizace',
    'Odvodnění pozemků',
    'Instalace ČOV a retenčních nádrží',
    'Výstavby vodovodů a kanalizací'
  ];

  return (
    <div id="sluzby" className={componentClass}>
      <div className={contentClass}>
        <div className={servicesClass}>
          <h2>
            Provádíme<span>/Služby</span>
          </h2>

          <ul>
            {items.map((i, idx) => (
              <li key={idx}>{i}</li>
            ))}
          </ul>

          <p>Jsme pojištěni ve výši 10.000.000&nbsp;Kč</p>
        </div>

        <div className={mapClass}>
          <div className={mapContentClass}>
            <h2>Působíme v Ústeckém&nbsp;kraji</h2>
            <p>Ústí nad Labem, Děčín, Most, Mělník, Liberec,&nbsp;...</p>
            <p>Po dohodě umíme zajistit také větší vzdálenost.</p>
          </div>

          <div className={bgClass}>
            <img src="/map_bg.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
