import React from 'react';

import { Seo } from '../components';

import {
  Fleet,
  Footer,
  Header,
  Intro,
  References,
  Services
} from '../sections';

const App = () => {
  return (
    <div id="layout">
      <Header />
      <Intro />
      <Services />
      <Fleet />
      <References />
      <Footer />
    </div>
  );
};

export const Head = () => <Seo />;

export default App;
