import React, { useRef } from 'react';

import { Link as ScrollLink } from 'react-scroll';

const Link = ({ ...props }) => {
  const ref = useRef(null);

  const onKeyDown = e => {
    if (e.code === 'Enter' || e.code === 'Space') {
      ref?.current?.handleClick(e);
    }
  };

  return <ScrollLink onKeyDown={onKeyDown} ref={ref} {...props} />;
};

export default Link;
